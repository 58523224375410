import React from 'react';
import PropTypes from 'prop-types';
import './LatestUpdates.scss';

const newsArticles = [
  {
    id: 1,
    title: "Urban Energy Innovations: The Future of Green Cities",
    date: "March 18, 2024",
    author: "Jane Doe",
    summary: "Exploring the latest innovations in urban energy solutions, including solar panels and wind turbines, transforming cities into sustainable ecosystems.",
    imageUrl: "https://cdn.pixabay.com/photo/2019/06/25/07/02/light-bulb-4297600_1280.jpg",
  },
  {
    id: 2,
    title: "The Rise of Smart Grids in Metropolitan Areas",
    date: "April 5, 2024",
    author: "John Smith",
    summary: "Smart grids are revolutionizing how cities manage energy distribution, enhancing efficiency and reducing waste. Discover how these systems are being implemented in urban centers.",
    imageUrl: "https://cdn.pixabay.com/photo/2022/01/10/15/29/wind-mills-6928590_640.jpg",
  },
  {
    id: 3,
    title: "Eco-friendly Public Transport: A Step Towards Urban Sustainability",
    date: "April 22, 2024",
    author: "Emily Johnson",
    summary: "Public transport systems powered by renewable energy sources are making cities more livable and reducing carbon footprints. This article looks at examples from around the world.",
    imageUrl: "https://cdn.pixabay.com/photo/2017/03/13/07/34/photovoltaic-2138992_640.jpg",
  },
  {
    id: 4,
    title: "Community Solar Projects: Empowering Urban Neighborhoods",
    date: "May 10, 2024",
    author: "Alex Lee",
    summary: "Community solar projects allow urban residents to benefit from solar energy without installing panels on their property. Learn how these projects are making a difference.",
    imageUrl: "https://cdn.pixabay.com/photo/2013/06/30/18/35/flying-sparks-142486_640.jpg",
  },
  {
    id: 5,
    title: "The Impact of Green Roofs on Urban Environments",
    date: "June 2, 2024",
    author: "Maria Gonzalez",
    summary: "Green roofs not only add beauty to urban landscapes but also play a crucial role in energy conservation and biodiversity. Explore the benefits and challenges of green roofs.",
    imageUrl: "https://cdn.pixabay.com/photo/2021/06/03/13/18/windmill-6307058_640.jpg",
  },
];


const LatestUpdates = () => (
  <div className={`LatestUpdates container title-container`}>
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="section-title mb-5">Latest Urban Energy Updates</h2>
      </div>
    </div>
    <div className="row">
      {newsArticles.map(article => (
        <div key={article.id} className="col-md-4 d-flex align-items-stretch">
          <div className="card">
            <img src={article.imageUrl} className="card-img-top" alt="..." />
            <div className="card-body d-flex flex-column">
              <h5 className="card-title">{article.title}</h5>
              <p>{article.date} by {article.author}</p>
              <hr />
              <p className="card-text">{article.summary}</p>
              <a href="#" className="button button-primary mt-auto">Read More</a>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

LatestUpdates.propTypes = {};

LatestUpdates.defaultProps = {};

export default LatestUpdates;

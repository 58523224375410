import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import logo from '../../assets/images/logo.png';

const Login = ({close}) => {
  const [showError, setShowError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); 
    setShowError(true);
  };

  const handleLoginContainerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.overlay} onClick={close}>
      <div className={styles.Login} onClick={handleLoginContainerClick}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="PFC Pro Logo" className={styles.logo}/>
          <span className="company-name"><b>PFC</b> Pro</span>
        </div>
        <h1>Welcome</h1>
        <p className={styles.subtitle}>Login to the PFC Pro portal</p>
        <form className={styles.loginForm} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <input type="email" placeholder="Email" required />
          </div>
          <div className={styles.inputGroup}>
            <input type="password" placeholder="Password" required />
          </div>
          {showError && <div className={styles.validationError}>Incorrect username or password.</div>}
          <button type="submit" className={styles.continueButton}>Continue</button>
        </form>
      </div>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;

import React from 'react';
import Header from '../../components/Header/Header';
import "./MainLayout.scss";
import Footer from '../../components/Footer/Footer';

const MainLayout = ({ children, showFooter = true, scrollToPricing}) => {
  return (
    <div className="body-wrap">
      <Header scrollToPricing={scrollToPricing}/>
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default MainLayout;

import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./Header.scss";
import logo from '../../assets/images/logo.png';
import Login from '../Login/Login';

const Header = ({ scrollToPricing }) => {
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLoginClick = () => setShowLogin(!showLogin);
  const closeLogin = () => setShowLogin(false);

  const handleHomeClick = (e) => {
    e.preventDefault();
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      window.location.href = '/';
    }
  };

  return (
    <>
      <header className="site-header">
        <nav className="navbar navbar-expand-lg navbar-custom">
          <div className="container">
            <a className="navbar-brand" href="/" onClick={handleHomeClick}>
              <img src={logo} alt="Company Logo" />
              <span className="company-name"><b>PFC</b> Pro</span>
            </a>

            <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarResponsive" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-list" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12.5A.5.5 0 0 1 2 12V11a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-11zm0-4A.5.5 0 0 1 2 8V7a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-11zm0-4A.5.5 0 0 1 2 4V3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-11z" />
              </svg>
            </button>

            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarResponsive">
              <div className="navbar-nav-container">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/" onClick={handleHomeClick}>Home</a>
                  </li>

                  <li className="nav-item">
                    <button className="nav-link" onClick={scrollToPricing}>Products</button>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/about-us">About Us</a>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
              <div className="navbar-right">
                <NavLink className="nav-link login-button" to="#" onClick={handleLoginClick}>Login</NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {showLogin &&
        <Login close={closeLogin}/>}
    </>
  );
}

export default Header;

import React, { useState } from 'react';
import './Experience.scss';
import WorldMap from '../../assets/images/world_map.svg';

const Experience = () => {

  const [selectedSpot, setSelectedSpot] = useState('sweden');
  const [hoveredSpot, setHoveredSpot] = useState(null);

  const handleClick = (spotId) => {
    setSelectedSpot(spotId);
  };

  const spotContent = {
    china: {
      country: "China",
      position: { top: '40%', left: '70%' },
      projects: [
        { year: "2024", project: "Wuhai 20km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2022", project: "Taian Heat Planning project", description: "Heat planning", role: "PFC Classic NXITY" },
        { year: "2021", project: "Songyuan 35km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2019-2021", project: "Sanmenxia District Heating Modernization project", description: "Conceptual Design, Operation Optimization and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2020", project: "Datong 40km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2019", project: "Xian Changan District Heating Optimization project", description: "Conceptual Design, Operation Optimization and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2018", project: "Beijing Smart District Heating project", description: "Conceptual Design, Operation Optimization and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2018", project: "Xian Yandong District Heating Optimization project", description: "Conceptual Design, Operation Optimization and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2017", project: "Xian Taihua District Heating Optimization project", description: "Conceptual Design, Operation Optimization and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2016", project: "Shijiazhuang 45km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2016", project: "Jixian 63km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic NXITY" },
        { year: "2015", project: "Jinan 70km District Heating Transmission Pipeline project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic Hydroram" }
      ],
    },
    canada: {
      country: "Canada",
      position: { top: '25%', left: '19%' },
      projects: [
        { year: "1992", project: "Edmonton District Heating and District Cooling", description: "Conceptual Design and Safety Analysis", role: "PFC Classic Hydroram" },
      ],
    },
    finland: {
      country: "Finland",
      position: { top: '21%', left: '53%' },
      projects: [
        { year: "2006", project: "Helsinki Nuclear Power Plant heat recovery project", description: "Concept Development", role: "PFC Classic Hydroram" },
      ],
    },
    india: {
      country: "India",
      position: { top: '48%', left: '66.5%' },
      projects: [
        { year: "2024", project: "Pune District Cooling system", description: "Pre-Feasibility Study", role: "PFC Pro NXITY" },
      ],
    },
    oman: {
      country: "Oman",
      position: { top: '48.5%', left: '60%' },
      projects: [
        { year: "2009", project: "Muscat and Salalah Airport District Cooling projects", description: "Safety Analysis", role: "PFC Classic COWI" },
      ],
    },
    qatar: {
      country: "Qatar",
      position: { top: '46%', left: '59%' },
      projects: [
        { year: "2011", project: "Lusail District Cooling system", description: "Conceptual Design, Design review, Safety Analysis", role: "PFC Classic NXITY" },
      ],
    },
    sweden: {
      country: "Sweden",
      position: { top: '20%', left: '50.6%' },
      projects: [
        { year: "2024", project: "Stockholm District Cooling system analysis", description: "Cooling planning, Conceptual Design, Development Strategy", role: "PFC Pro NXITY" },
        { year: "1990-2024", project: "Stockholm District Heating and District Cooling system analysis", description: "Expansion planning, Conceptual Design, Safety Analysis", role: "PFC Classic Stockholm Exergi" },
      ],
    },
    thailand: {
      country: "Thailand",
      position: { top: '52%', left: '72%' },
      projects: [
        { year: "2024", project: "Samyan Smart City District Cooling project", description: "Design review and Safety Analysis", role: "PFC Pro NXITY" },
      ],
    },
    "united-states": {
      country: "United States",
      position: { top: '38%', left: '21%' },
      projects: [
        { year: "2016", project: "Cayuga sea water intake project", description: "Conceptual Design and Safety Analysis", role: "PFC Classic Hydroram" },
      ],
    },

  };




  return (
    <section className="experience-section mb-5">
      <div className="map-container">
        <img src={WorldMap} alt="World Map" className="world-map" />

        {Object.entries(spotContent).map(([id, { country, projects, position }]) => (
          <div key={id} className={`info-spot ${selectedSpot === id ? "active" : ""}`} style={position}
            onMouseEnter={() => setHoveredSpot(id)}
            onMouseLeave={() => setHoveredSpot(null)}
            onClick={() => handleClick(id)}>
            {(hoveredSpot === id || selectedSpot === id) && (
              <div className="info-spot-tooltip">
                <div className="tooltip-country">{country}</div>
                <div className="tooltip-projects">{projects.length} {projects.length > 1 ? "Projects" : "Project"}</div>
              </div>
            )}
          </div>
        ))}

      </div>

      <div className="container title-container">
        <div className="info-section">
          <h2 className="country-heading">{spotContent[selectedSpot]?.country}</h2>
          <div className="project-container">
            {selectedSpot && spotContent[selectedSpot].projects.map((project, index) => (
              <div key={index} className="project-card">
                <div className="project-year">{project.year}</div>
                <div className="project-title">{project.project}</div>
                <div className="project-description">{project.description}</div>
                <div className="project-role">{project.role}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;

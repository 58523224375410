import React, { forwardRef, useState } from 'react';
import './Pricing.scss';

const Pricing = forwardRef((props, ref) => {

  const pricingData = {
    Planning: [
      { feature: "Planning", available: ["PFC Classic", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Design Optimization", available: ["PFC Classic", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Operation Optimization", available: ["PFC Classic", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Engaging UI", available: ["", "PFC Pro", "PFC Pro Connected"] },
      { feature: "OS and device independency", available: ["", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Multiple simultaneous users", available: ["", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Automated Import/Export data", available: ["", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Automated model trimming", available: ["", "PFC Pro", "PFC Pro Connected"] },
      { feature: "Live data API", available: ["", "", "PFC Pro Connected"] },
      { feature: "Live fault detection", available: ["", "", "PFC Pro Connected"] },
    ],
  };


  const [collapsedSections, setCollapsedSections] = useState({});

  const toggleSection = (section) => {
    setCollapsedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };


  return (
    <section ref={ref} className="pricing-section pt-5 pb-5">
      <div className="container title-container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="section-title mb-5">PFC Products</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="pricing-table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">PFC Classic</th>
                    <th scope="col">PFC Pro</th>
                    <th scope="col">PFC Pro Connected</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(pricingData).map(([section, features], index) => (
                    <React.Fragment key={section}>
                      {
                        features.map((feature) => (
                          <tr key={feature.feature}>
                            <td style={{ textAlign: "left" }}>{feature.feature}</td>
                            <td>{feature.available.includes("PFC Classic") ? "✔" : ""}</td>
                            <td>{feature.available.includes("PFC Pro") ? "✔" : ""}</td>
                            <td>{feature.available.includes("PFC Pro Connected") ? "✔" : ""}</td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
});

export default Pricing;

import React from 'react';
import './VisionMission.scss';

const VisionMission = () => (
  <section className="vision-mission-section">
    <div className="container title-container">
      <div className="row">
        <div className="col-12">
          <h1 className="section-heading">Our Vision and Mission</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="vision-container">
            <h2>Vision</h2>
            <p>Enable Decarbonization- and Conservation-at-Scale for transitioning to Sustainable and Resilient societies, by transforming the
              traditional utility sector(s) from independent businesses towards Multi-Utility Systems achieving Systemic Efficiency through
              Clustering, Sector Coupling, Energy Symbiosis and a Circular Economy approach.</p>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mission-container">
            <h2>Mission</h2>
            <p><b>Enable</b> – develop a state-of-the-art ‘utility system analyze tool’, methodology and working process for optimization of the
              entire project value chain, and through the entire project process (inc. development, investment, implementation and O&M).
            </p>
            <p>
              <b>Empower</b> – strengthen regions, municipalities, and utilities to develop sustainable and resilient energy and water
              infrastructure.
            </p>
            <p>
              <b>Localization</b> – close collaboration with clients and establishment of regional certified Competence Centers for municipal
              and regional energy and water utility development-, investment-, and O&M-optimization.
            </p>
            <p>
              <b>Accessibility</b> – capacity building and API’s with utilities’ present and future tools and support systems.</p>
          </div>
        </div>
      </div>

    </div>
  </section>
);

export default VisionMission;

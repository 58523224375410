import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Home.scss';
import Hero from '../../components/Hero/Hero';
import Feature from '../../components/Feature/Feature';
import Pricing from '../../components/Pricing/Pricing';
import PreFooter from '../../components/PreFooter/PreFooter';
import Footer from '../../components/Footer/Footer';
import GettingStarted from '../../components/GettingStarted/GettingStarted';
import LatestUpdates from '../../components/LatestUpdates/LatestUpdates';
import MainLayout from '../../layout/MainLayout/MainLayout';
import ProductValues from '../../components/ProductValues/ProductValues';
import ProductFeature from '../../components/ProductFeature/ProductFeature';
import { Helmet } from 'react-helmet';
import logoImage from '../../assets/images/pfc_logo.png';

const Home = ({ history }) => {
  const pricingRef = useRef(null);
  const location = useLocation();

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.state?.scrollToPricing) {
      pricingRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0); 
    }
  }, [location]); 

  const handleHomeClick = () => {
    if (location.pathname === '/home') {
      window.scrollTo(0, 0);
    } else {
      history.push('/home');
    }
  };

  const title = 'PFC Pro | Home';
  const desc = 'Revolutionizing Regional Urban Energy System Analysis and Optimization. Digital District Energy and Water Development and Management Twin';
  const url = '';
  const image = logoImage;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={desc} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image} />
      </Helmet>

      <MainLayout scrollToPricing={scrollToPricing}>
        <Hero />
        <Feature />
        <ProductFeature />
        <Pricing ref={pricingRef} />
        <PreFooter />
        <GettingStarted />
      </MainLayout>
    </>
  )
};

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

Home.defaultProps = {};

export default Home;

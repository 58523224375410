import React from 'react';
import './ProductFeature.scss';
import optimizationFeature from '../../assets/images/optimization-feature.png';
import planningFeature from '../../assets/images/planning-feature.png';
import SafetyIcon from '../../assets/images/safety.svg';
import SustainabilityIcon from '../../assets/images/sustainability.svg';
import ReliabilityIcon from '../../assets/images/reliability.svg';
import ProfitabilityIcon from '../../assets/images/profitability.svg';

const ProductFeature = () => (


  <section className="product-feature-section pt-5 pb-5">
    <div className="container title-container">
      
    {/* <div className="row mt-5">
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-pink">
            <img src={SafetyIcon} alt="Safety" />
            <p>Safety</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-red">
            <img src={SustainabilityIcon} alt="Sustainability" />
            <p>Sustainability</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-blue">
            <img src={ReliabilityIcon} alt="Reliability" />
            <p>Reliability</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-orange">
            <img src={ProfitabilityIcon} alt="Profitability" />
            <p>Profitability</p>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-12 text-center">
          <h2 className="section-title mb-5">Product Features</h2>
        </div>
      </div>

      <div className="row feature-row mb-5">
        <div className="col-md-3 col-12 img-column">
          <img src={planningFeature} alt="Feature 1" className="img-fluid mb-4" />
          <img src={optimizationFeature} alt="Feature 2" className="img-fluid" />
        </div>
        <div className="col-md-3 col-12">
          <h3>Systems</h3>
          <ul>
            <li>District Heating Systems (DH)</li>
            <li>District Cooling Systems (DC)</li>
            <li>District Heating and Cooling Systems (DHCS)</li>
            <li>Water & Wastewater</li>
            <li>Industrial Utilities</li>
            <li>Transmission pipelines</li>
          </ul>
          <h3>Planning</h3>
          <ul>
            <li>System dimensioning</li>
            <li>Modernization</li>
            <li>Expansion planning</li>
            <li>Emergency sectioning</li>
            <li>Project Due Diligence</li>
            <li>Scenario Analysis</li>
          </ul>
        </div>
        <div className="col-md-3 col-12">
          <h3>Design Optimization</h3>
          <ul>
            <li>Production facilities</li>
            <li>Transmission pipelines</li>
            <li>Substations</li>
            <li>Pump stations</li>
            <li>Pump / throttle stations</li>
            <li>Valve chambers</li>
            <li>Thermal energy storage</li>
            <li>Heat exchanger stations</li>
            <li>Pressure separators</li>
            <li>Safety arrangements</li>
            <li>Surge tanks</li>
            <li>Pressure relief valves</li>
            <li>Holding pressure facilities</li>
            <li>Mixing loops</li>
            <li>Bypasses</li>
          </ul>
        </div>
        <div className="col-md-3 col-12">
          <h3>Operation Optimization</h3>
          <ul>
            <li>Input to long-term and short-term production planning</li>
            <li>Operation manuals and guidelines</li>
            <li>Input for fuel purchase Commissioning simulations</li>
            <li>Maintenance planning support</li>
            <li>Overall system control strategy</li>
            <li>Control logic for main facilities</li>
            <li>Control logic for equipment</li>
            <li>Pressure optimization</li>
            <li>Temperature optimization</li>
            <li>Production optimization</li>
            <li>Pump trip analyses</li>
            <li>Valve closure analyses</li>
            <li>Trouble-shooting</li>
            <li>Water hammer analysis</li>
          </ul>
        </div>
      </div>

      
    </div>
  </section>
);

export default ProductFeature;

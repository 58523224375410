import React from 'react';
import './GettingStarted.scss';
import Image from '../../assets/images/planning-feature.png';
import { Link } from 'react-router-dom';

const GettingStarted = () => (
  <div className="getting-started-section">
    <div className="container title-container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <h1 className="section-title mb-4">Need help to take the next step?</h1>
          <h2 className="mb-3">Contact a PFC Pro Expert</h2>
          <div className='column-text mb-4'>Leave us your contact information and we'll get in touch.</div>
          <Link to="/contact" state={{ autofill: true }} className="button button-primary">
            Get in contact
          </Link>
        </div>
        <div className="col-12 col-md-6 image-align">
          <img src={Image} alt="Descriptive Alt Text" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
);

export default GettingStarted;

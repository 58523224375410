import React from 'react';
import './Timeline.scss';

const Timeline = () => (
  <section className="timeline-section py-5">
    <div className="container title-container">
      <div className="row">
        <div className="col-12">
          <h1 className="section-heading">Milestones of Innovation: The Evolution of PFC</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="timeline-container">
            <div className="timeline-box is-boxed">
              <div className="timeline-date">2025</div>
              <div className="timeline-content">
                <h2>PFC Pro Connected release</h2>
                <p>Our live Digital Twin, PFC Pro Connected, is released where real-time data, data analytics and
                  unique system analysis are combined as never seen before.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">2024</div>
              <div className="timeline-content">
                <h2>PFC Pro release</h2>
                <p>PFC Pro is released, with enhanced user engagement and unparalleled realism, enabling
                  cross-organizational collaboration while taking advantage of experiences and know-how from
                  NXITY, Hydroram, and hundreds of operators worldwide.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed">
              <div className="timeline-date">2021</div>
              <div className="timeline-content">
                <h2>PFC Pro development starts</h2>
                <p>In 2021, Mikael Jakobsson initiated the development of PFC Pro, in order to make the benefits of
                  PFC available and accessible for utilities and cities at larger scale, with the aim to support the
                  development of sustainable urban utilities.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">2015</div>
              <div className="timeline-content">
                <h2>NXITY</h2>
                <p>For decades, NXITY has applied PFC for development and optimization of District Cooling,
                  District Heating, Thermal Power, Water, and Industrial Utility projects worldwide. Despite access
                  to multiple Grid Simulation Softwares, nothing could compare with the level of analysis PFC could
                  perform.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed">
              <div className="timeline-date">2005</div>
              <div className="timeline-content">
                <h2>Mikael Jakobsson</h2>
                <p>During his Master’s education at the Royal Institute of Technology (KTH), Mikael Jakobsson
                  starts using PFC for system analysis of Stockholm integrated District Heating and District Cooling
                  system.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">2004</div>
              <div className="timeline-content">
                <h2>Hydroram</h2>
                <p>Hydroram, co-owned by Ola Rossing and Gunnar Larrson, acquires PFC and develops the Xram
                  shell for improved output, scenario analyses, among other outstanding functionalities.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">1995</div>
              <div className="timeline-content">
                <h2>Gunnar Larsson</h2>
                <p>During his PhD education at Chalmers Instutite of Technology (CTH), Gunnar Larsson applies
                  PFC both for his research and on commercial basis with Ola Rossing.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">1990</div>
              <div className="timeline-content">
                <h2>Ola Rossing</h2>
                <p>The establishment of Ola Rossing AB marks a significant milestone , applying PFC on
                  commercial basis and initiating a continuous journey of innovation and development, enriching its
                  feature set and functionalities.</p>
              </div>
            </div>
            <div className="timeline-box is-boxed timeline-box-right">
              <div className="timeline-date">1983</div>
              <div className="timeline-content">
                <h2>Bror-Arne Gustafsson</h2>
                <p>The genesis of PFC at Chalmers Technical University, by Licentiate Bror-Arne Gustafsson,
                  setting the foundation for what would become a key tool in the analysis for developing and
                  optimizing sustainable urban systems.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Timeline;

import React, { useEffect, useState } from 'react';
import './Contact.scss';
import MainLayout from '../../layout/MainLayout/MainLayout';
import { useLocation, useNavigate  } from 'react-router-dom';
import { countries } from 'countries-list';
import { Helmet } from 'react-helmet';
import logoImage from '../../assets/images/pfc_logo.png'

const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const autofill = location.state?.autofill;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [country, setCountry] = useState('');
  const [subject, setSubject] = useState('');
  const countryOptions = Object.values(countries).map((country) => ({
    name: country.name,
  }));
  const title = 'PFC Pro | Contact Us';
  const desc = 'Revolutionizing Regional Urban Energy System Analysis and Optimization. Digital District Energy and Water Development and Management Twin';
  const url = '';
  const image = logoImage;

  const [message, setMessage] = useState('');

  const [errors, setErrors] = useState({});
  const scrollToPricing = () => {
    navigate('/', { state: { scrollToPricing: true } });
  };

  useEffect(() => {
    // console.log(autofill);
    if (autofill) {
      // console.log("set message")
      setMessage("I'm interested in learning more about PFC Pro and need assistance on the next steps. Please reach out to me as soon as possible.");
    }
  }, [autofill]);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First name is required";
      isValid = false;
    }

    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
      isValid = false;
    }

    if (!emailAddress.trim()) {
      errors.emailAddress = "Email address is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      errors.emailAddress = "Email address is invalid";
      isValid = false;
    }

    if (!country.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = {
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        companyName: companyName,
        country: country,
        subject: subject,
        message: message
      };
     
  
      try {
        const response = await fetch('https://e01xnl3r72.execute-api.ap-southeast-1.amazonaws.com/prod/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            //'Accept': 'application/json',
          },
          body: JSON.stringify(formData)
        });
  
        if (!response.ok) throw new Error('Failed to send contact form');
  
        const data = await response.json();
        // console.log('Success:', data);
  
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setCompanyName('');
        setCountry('');
        setSubject('');
        setMessage('');
  
      } catch (error) {
        // console.error('Error:', error);
        // alert('Error submitting form, please try again.');
      }
    } else {
      console.log("Form validation failed", errors);
      // alert('Please check your inputs and try again.');
    }
  };
  

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={desc} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <MainLayout showFooter={false} scrollToPricing={scrollToPricing}>
        <div className="Contact d-flex">
          <div className="instructions col-md-4">
            <div className='instructions-container'>
              <h1>Get in Touch with Our Specialists!</h1>
              <p>Complete the form to let
                us know how we can
                assist you in the best
                way:</p>
              <ul>
                <li>Exploring how we can
                  support you to overcome
                  your development and/or
                  operation challenges</li>
                <li>Understanding the full
                  range of functionalities
                  we offer</li>
                <li>Discussing your options for products and their pricing</li>
              </ul>
            </div>
          </div>
          <div className="contact-container">
            <h2>Contact Us</h2>
            <form className='contact-form' onSubmit={handleSubmit}>
            <div className="form-group">
  <label htmlFor="firstName">First Name *</label>
  <input type="text" className="form-control" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} required />
</div>

<div className="form-group">
  <label htmlFor="lastName">Last Name *</label>
  <input type="text" className="form-control" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} required />
</div>

<div className="form-group">
  <label htmlFor="emailAddress">Email Address *</label>
  <input type="email" className="form-control" id="emailAddress" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required />
</div>

<div className="form-group">
  <label htmlFor="companyName">Company Name *</label>
  <input type="text" className="form-control" id="companyName" value={companyName} onChange={e => setCompanyName(e.target.value)} required />
</div>

<div className="form-group">
  <label htmlFor="country">Country *</label>
  <select className="form-control" id="country" value={country} onChange={e => setCountry(e.target.value)} required>
    <option value="">Select a country</option>
    {countryOptions.map((option, index) => (
      <option key={index} value={option.name}>{option.name}</option>
    ))}
  </select>
</div>

<div className="form-group">
  <label htmlFor="subject">Subject *</label>
  <select className="form-control" id="subject" value={subject} onChange={e => setSubject(e.target.value)} required>
    <option value="">Select a query</option>
    <option value="Project Support">Project support</option>
    <option value="PFC functionalities">PFC functionalities</option>
    <option value="Pricing and offers">Pricing and offers</option>
    <option value="Other">Other</option>
  </select>
</div>

<div className="form-group mb-5">
  <label htmlFor="message">Message *</label>
  <textarea className="form-control" id="message" rows="3" required value={message} onChange={e => setMessage(e.target.value)}></textarea>
</div>
              <button className="button button-primary" type='submit' style={{width: '100%'}}>Submit</button>
            </form>
          </div>
        </div>
      </MainLayout>
    </>
  )
};

export default Contact;

import React from 'react';
import './Feature.scss';
import collab from '../../assets/images/collab.svg';
import education from '../../assets/images/education.svg';
import realism from '../../assets/images/realism.svg';
import userEngagement from '../../assets/images/user-engagement.svg';
import SafetyIcon from '../../assets/images/safety.svg';
import SustainabilityIcon from '../../assets/images/sustainability.svg';
import ReliabilityIcon from '../../assets/images/reliability.svg';
import ProfitabilityIcon from '../../assets/images/profitability.svg';

const Feature = () => {
  return (
    <section className="features-section pt-5 pb-5">
      <div className="container title-container">

      
      <div className="row mt-5">
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-pink">
            <img src={SafetyIcon} alt="Safety" />
            <p>Safety</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-red">
            <img src={SustainabilityIcon} alt="Sustainability" />
            <p>Sustainability</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-blue">
            <img src={ReliabilityIcon} alt="Reliability" />
            <p>Reliability</p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 mb-4">
          <div className="value-container bg-orange">
            <img src={ProfitabilityIcon} alt="Profitability" />
            <p>Profitability</p>
          </div>
        </div>
      </div>
  
        
        
        <div className="row">
          <div className="col-12 text-center">
            
            <h2 className="section-title mb-3">Signature of PFC Pro</h2>
          </div>
        </div>
        
        <div className="row mt-3 justify-content-center">
          <div className="col-md-6 col-lg-5 col-xl-3 text-center mb-4">
            <div className="feature shadowed-container">
              <div className="d-flex mb-3">
                <div className="feature-icon">
                  <img src={userEngagement} alt="User Engagement" style={{ width: '60px' }} />
                </div>
                <div className="ms-3">
                  <h5 className="feature-title">User Engagement</h5>
                  <i className="key-phrases">Complex analysis in an intuitive environment</i>
                </div>
              </div>
              <p className="feature-description">Complex analysis in an intuitive environment
                PFC Pro is developed with techniques to enhance user
                engagement, and a systematic working methodology for
                planning, development, and O&M of municipal and industrial
                utilities.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-5 col-xl-3 text-center mb-4">
            <div className="feature shadowed-container">
              <div className="d-flex mb-3">
                <div className="feature-icon">
                  <img src={realism} alt="Unparalleled Realism" style={{ width: '60px' }} />
                </div>
                <div className="ms-3">
                  <h5 className="feature-title">Unparalleled Realism</h5>
                  <i className="key-phrases">Authentic simulations</i>
                  <br/> &nbsp;
                </div>
              </div>
              <p className="feature-description">Build up complex facilities in your model, with components
                and their characteristics, just as they are in reality. With
                independent control functions, you can operate the system in a
                true digital twin.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-5 col-xl-3 text-center mb-4">
            <div className="feature shadowed-container">
              <div className="d-flex mb-3">
                <div className="feature-icon">
                  <img src={collab} alt="Collaborative Approach" style={{ width: '60px' }} />
                </div>
                <div className="ms-3">
                  <h5 className="feature-title">Collaborative Approach</h5>
                  <i className="key-phrases">Meeting point for operational excellence</i>
                </div>
              </div>
              <p className="feature-description">Meeting point for operational excellence

                Developed for cross- and inter-organizational project work
                and simultanous team work. PFC Pro is the platform for the
                organization to gather around, for sound and fact-based
                decision making.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-5 col-xl-3 text-center mb-4">
            <div className="feature shadowed-container">
              <div className="d-flex mb-3">
                <div className="feature-icon">
                  <img src={education} alt="Experience & Expertise" style={{ width: '60px' }} />
                </div>
                <div className="ms-3">
                  <h5 className="feature-title">Experience & Expertise</h5>
                  <i className="key-phrases">Built on unprecedented skills</i>
                  <br/> &nbsp;
                </div>
              </div>
              <p className="feature-description">Gain from our vast experience and know-how. Our solutions are
                developed by international experts with real-life experience from
                over 500 District Energy systems worldwide, adopting clients
                needs and priorities.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;

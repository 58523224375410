import React from 'react';
import './Hero.scss';
import HeroImage from '../../assets/images/hero-redesign.svg';

const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy">
            <h1 className="hero-title mt-0">Revolutionizing Regional Urban Energy System Analysis and Optimization</h1>
            <p className="hero-paragraph">Digital District Energy and Water <br/> Development and Management Twin</p>
            <div className="hero-cta">
              <a className="button button-primary" href=" /contact">Contact Us</a>
              <a className="button" href="/about-us">Learn more</a>
            </div>
          </div>
          <div className="hero-figure">
            <img src={HeroImage}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React, { useEffect } from 'react';
import './About.scss';
import Experience from '../../components/Experience/Experience';
import VisionMission from '../../components/VisionMission/VisionMission';
import Timeline from '../../components/Timeline/Timeline';
import MainLayout from '../../layout/MainLayout/MainLayout';
import { Helmet } from 'react-helmet';
import logoImage from '../../assets/images/pfc_logo.png'
import { useNavigate  } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();
  const scrollToPricing = () => {
    navigate('/', { state: { scrollToPricing: true } });
  };

  useEffect(() => {
    console.log('Setting scroll to top');
  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'  // This ensures the scroll is immediate without any smooth effect.
    });
  }, []);

  const title = 'PFC Pro | About Us';
  const desc = 'Revolutionizing Regional Urban Energy System Analysis and Optimization. Digital District Energy and Water Development and Management Twin';
  const url = '';
  const image = logoImage;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={desc} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <MainLayout scrollToPricing={scrollToPricing}>
        <Experience />
        <VisionMission />
        <Timeline />
      </MainLayout>
    </>
  )
};

export default About;
